<template>
    <section id="dashboard-analytics">
        <b-row class="match-height">
            <b-col lg="12" sm="12">
                <b-card v-if="statistics" no-body>
                    <b-card-header>
                        <b-card-title>Dashboard</b-card-title>
                    </b-card-header>
                    <b-card-body>
                        <b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
                            <b-row>
                                <b-col v-for="item in statistics" :key="item.icon" xl="3" sm="6" :class="item.customClass">
                                    <b-media no-body>
                                        <b-media-aside class="mr-2">
                                            <b-avatar size="48" :variant="item.color">
                                                <feather-icon size="24" :icon="item.icon" />
                                            </b-avatar>
                                        </b-media-aside>
                                        <b-media-body class="my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                {{ item.title }}
                                            </h4>
                                            <b-card-text class="font-small-4 mb-0">
                                                {{ item.subtitle }}
                                            </b-card-text>
                                        </b-media-body>
                                    </b-media>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
    import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay } from 'bootstrap-vue'

    export default {
        components: {
            BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay
        },
        data() {
            return {
                isFetchingRecordsFromServer: true,
                statistics: {
                    sites: {
                        icon: "GlobeIcon",
                        color: "light-primary",
                        title: 0,
                        subtitle: "Sites",
                        customClass: "border p-1 m-1"
                    },
                    users: {
                        icon: "UserIcon",
                        color: "light-info",
                        title: 0,
                        subtitle: "Site Users",
                        customClass: "border p-1 m-1"
                    }
                },
            }
        },
        created() {
            this.fetchDashboardData();
        },
        methods: {
            fetchDashboardData() {
                this.isFetchingRecordsFromServer = true;
                this.$globalAPI.get('get-dashboard-data', {
                    user_id: this.$root.authUserObject?.id
                }).then((response) => {
                    response = response.data;
                    this.statistics.users.title = response.totalUsersCount
                    this.statistics.sites.title = response.totalSitesCount
                    this.isFetchingRecordsFromServer = false;
                });
            },
        },
    }
</script>